<template>
  <v-row>
    <v-col class="mt-6 pb-0" cols="12">
      <v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="addIncident">Add Incident</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>

    <v-col cols="12">
      <v-card>
        <v-card-text>
          <v-dialog v-model="progressDialog" width="500">
            <v-card>
              <v-card-title class="headline grey lighten-2" primary-title>File download</v-card-title>

              <v-card-text>
                <br />
                {{progressDescription}}
                <v-progress-linear v-model="downloadProgress" :active="true"></v-progress-linear>
              </v-card-text>
              <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn color="primary" text @click="progressDialog = false">close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-data-table
            id="list-table"
            ref="datatable"
            :headers="headers"
            :items="incidents"
            item-key="index"
            class="mr-2"
          >
            <template slot="item" slot-scope="props">
              <tr>
                <td>{{ props.item.incidentOverview.incidentTitle}}</td>
                <td>{{ props.item.incidentCategory==undefined?"": props.item.incidentCategory.incidentPrimaryCategory }}</td>
                <td>{{ props.item.incidentOverview==undefined?"":props.item.incidentOverview.incidentNotificationType }}</td>
                <td>{{ props.item.providerDetails==undefined?"": props.item.providerDetails.reportCompletedBy }}</td>

                <td>{{ moment(props.item.createdOn).format("DD-MM-YYYY")}}</td>

                <td>
                  <v-icon
                    slot="activator"
                    small
                    class="mr-2"
                    @click="editItem(props.item)"
                  >mdi-pencil</v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Vue from "vue";
import VeeValidate from "vee-validate";
import * as incidentService from "../../../services/incidentsService";
Vue.use(VeeValidate);

import moment from "moment";
export default {
  name: "incidents",
  components: {},
  data: () => ({
    editedIndex: -1,
    vm: {},
    show: false,
    downloadProgress: 0,
    dialog: false,
    progressDialog: false,
    progressDescription: "",
    incidents: [],
    rowPageConfig: [
      50,
      100,
      200,
      { text: "$vuetify.dataIterator.rowsPerPageAll", value: -1 }
    ],
    headers: [
      { text: "Incident Title", value: "incidentTitle" },
      { text: "Primary Category", value: "incidentPrimaryCategory" },
      { text: "Notification Type", value: "incidentNotificationType" },
      { text: "Report Completed By", value: "reportCompletedBy" },
      { text: "Created on", value: "createdon" },
      { text: "", value: "" }
    ]
  }),
  mounted() {
    this.init();
  },
  computed: {
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    currentCase() {
      return this.$store.getters.currentCase;
    }
  },
  methods: {
    moment: function(date) {
      return date != undefined ? moment(date) : moment();
    },

    init() {
      this.vm = {};
      incidentService
        .list(this.currentOrganisation.id, this.currentCase.id)
        .then(result => {
          let incidents = [];
          result.docs.map(doc => {
            const incident = doc.data();
            incident.id = doc.id;
            incidents.push(incident);
          });
          this.incidents = incidents;
        });
    },
    download(item) {
      console.log(item);
      const me = this;
      this.progressDialog = true;
      this.downloadProgress = 0;
      this.progressDescription = `Received 0 of 0 bytes`;
      let report = "reportIncidentImmediate";

      if (item.incidentNotificationType === "5 Day Notification") {
        report = "reportIncident5DayNotification";
      }
      const projectId = process.env.VUE_APP_projectId; //"omar-uat";
      const data = {
        organisationId: this.currentOrganisation.id,
        caseId: this.currentCase.id,
        incidentId: item.id
      };
      const url = `https://us-central1-${projectId}.cloudfunctions.net/${report}`;
      const xhr = new XMLHttpRequest();
      xhr.open("POST", url);
      xhr.setRequestHeader("Content-type", "application/json");
      xhr.responseType = "blob";
      console.log(data);
      xhr.send(JSON.stringify(data)); // Make sure to stringify
      xhr.onload = function() {
        // Do whatever with response
        //  alert(xhr.responseText);

        if (this.status == 200) {
          // Create a new Blob object using the response data of the onload object
          var blob = new Blob([this.response], {
            type: "application/octet-stream"
          });
          //Create a link element, hide it, direct it towards the blob, and then 'click' it programatically
          let a = document.createElement("a");
          a.style = "display: none";
          document.body.appendChild(a);
          //Create a DOMString representing the blob and point the link element towards it
          let url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = item.incidentOverview.incidentTitle + ".docx";
          //programatically click the link to trigger the download
          a.click();
          //release the reference to the file by revoking the Object URL

          setTimeout(function() {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
          }, 100);
        } else {
          console.log(xhr);
        }
      };

      xhr.onerror = function() {
        // only triggers if the request couldn't be made at all
        item.loading = false;
      };
      xhr.onprogress = function(event) {
        // triggers periodically
        // event.loaded - how many bytes downloaded
        // event.lengthComputable = true if the server sent Content-Length header
        // event.total - total number of bytes (if lengthComputable)
        me.progressDescription = `Received ${event.loaded} of ${event.total} bytes`;
        me.downloadProgress = (event.loaded / event.total) * 100;
        //  item.loading = false;
        me.$forceUpdate();
      };

      xhr.onreadystatechange = function() {
        if (xhr.readyState == 3) {
          // loading
          //this.progressDialog = true;
        }
        if (xhr.readyState == 4) {
          // request finished
          me.progressDialog = false;
        }
      };
    },
    editItem(item) {
      this.$store.commit("setCurrentIncident", item);
      this.$router.push("/participant-management/incidents/main-form");
    },

    addIncident() {
      this.$store.commit("setCurrentIncident", null);
      this.$router.push("/participant-management/incidents/main-form");
    }
  }
};
</script>
<style>
.v-card--material-stats:hover {
  border-top: 4px solid #4caf50;
}
</style>